import React from 'react'
import { graphql } from 'gatsby'

import PageHeader from '../components/PageHeader'
import Content from '../components/Content'
import CallToAction from '../components/CallToAction'
import Layout from '../components/Layout'

// Export Template for use in CMS preview
export const DefaultPageTemplate = ({ title, sectionHero, body }) => (
  <div className="about-page">
    <PageHeader title={title} backgroundImage={sectionHero.featuredImage} />

    {/* content section */}

    <section>
      <div className="thin">
        <Content src={body} />
      </div>
    </section>

    <CallToAction />
  </div>
)

const DefaultPage = ({ data: { page } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <DefaultPageTemplate {...page.frontmatter} body={page.html} />
  </Layout>
)
export default DefaultPage

export const pageQuery = graphql`
  query DefaultPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        sectionHero {
          featuredImage
        }
      }
    }
  }
`
